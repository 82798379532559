export const useSuccessStore = defineStore("success", {
  state: () => {
    return {
      displayModal: false,
      modalText: "",
    };
  },

  getters: {},

  actions: {
    showModal(text: string): void {
      this.modalText = text;
      this.displayModal = true;
      setTimeout(() => {
        this.closeModal();
      }, 2000);
    },
    closeModal(): void {
      this.displayModal = false;
      this.modalText = "";
    },
  },
});
